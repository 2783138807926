import request from "@/utils/request";

export function positionList(data) {
  return request({
    method: "POST",
    url: "employee/list_position",
    data: data
  });
}

export function positionDelete(data) {
  return request({
    method: "DELETE",
    url: `employee/delete_position/${data.id}`,
    data: data
  });
}

export function positionAdded(data) {
  return request({
    method: "POST",
    url: "employee/create_position",
    data: data
  });
}

export function positionEdit(data) {
  return request({
    method: "POST",
    url: "employee/update_position",
    data: data
  });
}

export function masterAccess(data) {
  return request({
    method: "POST",
    url: "access/get_all_group_position",
    data: data
  });
}
