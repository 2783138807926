<template>
  <v-card class="pa-4" flat persistent>
    <v-row justify="center">
      <v-col cols="12" sm="6">
        <v-btn
          :loading="loadingBtnAdd"
          class="caption mr-2 white--text"
          color="gradient-primary"
          depressed
          dense
          @click="openModal()"
        >
          {{ $t("master_data.position.add_position") }}
          <v-icon class="ml-1">mdi-plus-circle-outline</v-icon>
        </v-btn>

        <download-excel
          :data="dataTable"
          :fields="excel"
          :name="`${$t('routes.position')}.xls`"
          class="d-inline"
        >
          <v-btn
            :disabled="dataTable.length == 0"
            class="caption mr-2 white--text"
            color="gradient-primary"
            dense
            depressed
          >
            <span class="mr-2">
              {{ $t("app.download") }}
            </span>
            <v-icon>mdi-cloud-download</v-icon>
          </v-btn>
        </download-excel>
      </v-col>
      <v-col cols="12" sm="6">
        <v-text-field
          v-model="queryData.search"
          :label="$t('app.search')"
          :style="
            $vuetify.breakpoint.smAndUp ? 'float: right' : 'margin-top: 7px'
          "
          prepend-inner-icon="mdi-magnify"
          single-line
          outlined
          hide-details
          dense
          clearable
          class="caption--text"
          style="max-width: 300px;"
        />
      </v-col>
    </v-row>

    <v-divider class="my-4" />

    <v-data-table
      :headers="tableHeaders"
      :items="dataTable"
      :loading="loading"
      :sort-desc="['id']"
      :height="$vuetify.breakpoint.mdAndUp ? '78vh' : '100%'"
      :search="queryData.search"
      disable-pagination
      fixed-header
      hide-default-footer
      class="elevation-0"
    >
      <template v-slot:item.action="{ item }">
        <div>
          <v-btn
            :loading="item.loading"
            :disabled="loading"
            icon
            small
            dark
            class="primary mr-2"
            @click="openModal(item)"
          >
            <v-icon small>mdi-pencil-box-outline</v-icon>
          </v-btn>
          <v-btn
            :disabled="loading"
            icon
            small
            dark
            class="error"
            @click="confirmDelete(item)"
          >
            <v-icon small>mdi-delete-forever</v-icon>
          </v-btn>
        </div>
      </template>
    </v-data-table>

    <Dialog
      :modal="modalDialog"
      :dataPosition="positionBody"
      :isEdit="isEdit"
      :loading="loadingBtn"
      :routeAccess="routeAccess"
      @close="closeModal"
      @save="savePosition"
      @addedPosition="add"
      @removePosition="remove"
    />

    <ModalConfirm
      :close="() => (this.modalDialogDelete = false)"
      :loading="loadingBtnDelete"
      :isOpen="modalDialogDelete"
      :save="deletePosition"
    />
  </v-card>
</template>

<script>
import i18n from "@/i18n";
import {
  positionList,
  positionDelete,
  positionAdded,
  positionEdit
} from "@/api/admin/master/position";
import { masterAccess } from "@/api/admin/master/position";

export default {
  metaInfo: {
    title: i18n.t("routes.position"),
    titleTemplate: `%s | ${process.env.VUE_APP_APPLICATION_NAME}`
  },
  components: {
    Dialog: () => import("./dialog"),
    ModalConfirm: () => import("@/components/ModalConfirm")
  },
  created() {
    this.initData();
  },
  // changed searching frontend
  // watch: {
  //   "queryData.search"() {
  //     this.initData();
  //   }
  // },
  data() {
    return {
      loadingBtn: false,
      loadingBtnAdd: false,
      modalDialogDelete: false,
      modalDialog: false,
      isEdit: false,
      excel: {
        Jabatan: "position"
      },
      tableHeaders: [
        {
          text: i18n.t("master_data.position.table_position"),
          value: "position"
        },
        {
          text: i18n.t("app.action"),
          sortable: false,
          value: "action",
          width: 120
        }
      ],
      positionBody: {
        id: 0,
        position: [""],
        description: "",
        kodes: []
      },
      routeAccess: [],
      loading: false,
      dataTable: [],
      queryData: {
        search: "",
        sort: "",
        order: ""
      },
      isDialogList: false,
      idDelete: 0,
      loadingBtnDelete: false
    };
  },
  methods: {
    resetBody() {
      this.routeAccess = [];
      this.positionBody = {
        id: 0,
        position: [""],
        description: "",
        kodes: []
      };
    },
    add() {
      this.positionBody.position.push("");
    },
    remove(index) {
      this.positionBody.position.splice(index, 1);
    },
    confirmDelete(item) {
      this.idDelete = item.id;
      this.modalDialogDelete = true;
    },
    deletePosition() {
      this.loadingBtnDelete = true;
      positionDelete({ id: this.idDelete })
        .then(res => {
          if (res.data.code === 1) {
            this.snackBar(false, res.data.message);
            this.modalDialogDelete = false;
            this.initData();
          } else {
            this.snackBar(true, res.data.message);
            this.modalDialogDelete = true;
          }
          this.loadingBtnDelete = false;
        })
        .catch(err => {
          this.loadingBtnDelete = false;
          this.snackBar(true, err);
        });
    },
    async getRouteAccess(id) {
      try {
        const response = await masterAccess({ position_id: id || 0 });
        if (response.data.code) {
          const result = response.data.data;
          return result;
        }
      } catch (error) {
        console.log("openModal()", error);
      }
    },
    async openModal(item) {
      let result = {};
      if (item) {
        const id = item.id;
        this.isEdit = true;
        this.positionBody.id = id;
        this.positionBody.position = [item.position];
        this.positionBody.description = item.description;
        item.loading = true;
        result = await this.getRouteAccess(id);
        item.loading = false;
      } else {
        this.loadingBtnAdd = true;
        result = await this.getRouteAccess(0);
        this.isEdit = false;
        this.resetBody();
      }

      const tempCodes = [];
      result.access.map((r, i) => {
        if (r.children.length > 0) {
          r.children.map(rc => {
            if (result.kodes.some(p => rc.kodes.includes(p))) {
              if (rc.kodes.length > 0) {
                rc.kodes.map(kode => {
                  tempCodes.push({ kode: kode, idx: i });
                });
              }
            }
          });
        }
      });

      let access = result.access;
      tempCodes.map(t => {
        result.access[t.idx].kodes.push(t.kode);
      });

      this.routeAccess = access;
      this.positionBody.kodes = [...result.kodes, ...tempCodes];
      this.loadingBtnAdd = false;
      this.modalDialog = true;
    },
    snackBar(isError, msg) {
      this.$store.commit("CALL_SNACKBAR", {
        msg: msg,
        color: isError ? "error" : "success"
      });
      this.loadingSave = false;
    },
    listData() {
      this.loading = true;
      positionList(this.queryData)
        .then(res => {
          this.dataTable = res.data.data.map(item => ({
            ...item,
            loading: false
          }));
          this.loading = false;
        })
        .catch(err => {
          this.loading = false;
          this.snackBar(true, err);
        });
    },
    closeModal() {
      this.modalDialog = false;
      this.resetBody();
    },
    async savePosition() {
      // this.loadingBtn = true;
      const arrayKodes = await this.routeAccess.map(access => access.kodes);
      const codes = [].concat(...arrayKodes);

      this.positionBody.kodes = [...codes];

      if (this.isEdit) {
        let tempBody = {
          id: this.positionBody.id,
          position: this.positionBody.position[0],
          kodes: this.positionBody.kodes
        };
        positionEdit(tempBody)
          .then(res => {
            if (res.data.code) {
              this.modalDialog = false;
              this.snackBar(
                false,
                i18n.t("master_data.position.response.success_edit_position")
              );
              this.loadingBtn = false;
              this.initData();
            } else {
              this.snackBar(true, res.data.message);
            }
          })
          .catch(err => {
            this.loadingBtn = false;
            this.snackBar(true, err);
          });
      } else {
        positionAdded({
          position: this.positionBody.position[0],
          kodes: this.positionBody.kodes
        })
          .then(res => {
            if (res.data.code) {
              this.modalDialog = false;
              this.snackBar(
                false,
                i18n.t("master_data.position.response.success_add_position")
              );
              this.initData();
            } else {
              this.snackBar(true, res.data.message);
            }
            this.loadingBtn = false;
          })
          .catch(err => {
            this.snackBar(true, err);
            this.loadingBtn = false;
          });
        // const promises = this.positionBody.position.map(async d => {
        //   if (d) {
        //     try {
        //       const res = await positionAdded({
        //         position: d,
        //         kodes: this.positionBody.kodes
        //       });
        //       if (res.data.code) {
        //         return res.data.data;
        //       } else {
        //         this.snackBar(true, res.data.message);
        //       }
        //     } catch (error) {
        //       this.snackBar(true, error);
        //     }
        //   }
        // });
        // const response = await Promise.all(promises);
        // if (response.length > 0) {
        //   this.modalDialog = false;
        //   this.loadingBtn = false;
        //   this.snackBar(
        //     false,
        //     i18n.t("master_data.position.response.success_add_position")
        //   );
        //   this.initData();
        // }
      }
    },
    initData() {
      this.listData();
      this.resetBody();
    }
  }
};
</script>
